define(['app', '$window'], (app, $window) => {

	const nearbyStore = () => {
		const component = {};

		const _config = {
			selectors: {
				nearbyStoreContentButtons: '.nearbyStore_content_button',
				nearbyStoreContentButtonContainer: '.nearbyStore_content_buttonContainer',
				nearbyStoreHeaderTitles: '.nearbyStore_header_title',
				previouslySelectedStoreName: '.previouslySelectedStore_storeName',
				selectedStore: '.responsiveSubMenu_headerStoreLocatorText_store',
			},
			attributes: {
				nearbyStoreMyStore: 'data-nearbyStore-myStore',
				nearbyStoreSetAsMyStore: 'data-nearbyStore-setAsMyStore',
				storeName: 'data-store-name',
				storeUrl: 'data-store-url',
				storeOpeningTimes: 'data-store-opening-times',
				storeLongitude: 'data-store-longitude',
				storeLatitude: 'data-store-latitude',
				storePostcode: 'data-store-postcode',
			},
			lSKeys: {
				selectedStore: "selectedStore",
				prevSelectedStore: "previouslySelectedStore",
			},
			channels: {
				nearbyStoresPageUpdate: 'nearByStoresPageUpdate',
				nearbyStoresHeaderUpdate : 'storeDetailHeaderUpdate',
				headerSelectedStore: 'headerSelectedStore',
			}
		};

		const _init = (element) => {
			component.element = element;
			component.nearbyStoreContentButtons = component.element.querySelectorAll(component.config.selectors.nearbyStoreContentButtons);
			component.nearbyStoreContentButtonContainer = component.element.querySelector(component.config.selectors.nearbyStoreContentButtonContainer);
			component.nearbyStoreHeaderTitles = component.element.querySelectorAll(component.config.selectors.nearbyStoreHeaderTitles);
			component.previouslySelectedStoreName = document.querySelector(component.config.selectors.previouslySelectedStoreName);
			component.selectedStore = document.querySelector(component.config.selectors.selectedStore);

			component.addEventListeners();
			component.updateButtonContent();
			app.subscribe(component.config.channels.headerSelectedStore, component.updateButtonContent);

			return component;
		};

		const _addEventListeners = () => {
			component.nearbyStoreContentButtons && component.nearbyStoreContentButtons.forEach (button => {
				button.addEventListener('click', () => {
          component.updateSelectedStore(button);
				});
			});
		};

		const _updateSelectedStore = (button) => {
      const selectedStore = button.getAttribute(component.config.attributes.storeName);
      const selectedStoreURL = button.getAttribute(component.config.attributes.storeUrl);
      const selectedStoreOpeningTimes = button.getAttribute(component.config.attributes.storeOpeningTimes);
      const selectedStoreLongitude = button.getAttribute(component.config.attributes.storeLongitude);
      const selectedStoreLatitude = button.getAttribute(component.config.attributes.storeLatitude);
      const selectedStorePostcode = button.getAttribute(component.config.attributes.storePostcode);
      app.publish(component.config.channels.nearbyStoresPageUpdate, selectedStore, selectedStoreURL, selectedStoreOpeningTimes, selectedStoreLongitude, selectedStoreLatitude, selectedStorePostcode);
      component.updateButtonContent();
      app.publish(component.config.channels.nearbyStoresHeaderUpdate);
    }

		const _updateButtonContent = () => {
      const currentlySelectedStore = component.getFromLS(component.config.lSKeys.selectedStore);
			component.nearbyStoreContentButtons.forEach((button) => {
				if(button.getAttribute(component.config.attributes.storeName) === currentlySelectedStore) {
					button.innerHTML = button.getAttribute(component.config.attributes.nearbyStoreMyStore);
				} else {
          button.innerHTML = button.getAttribute(component.config.attributes.nearbyStoreSetAsMyStore);
        }
			});
		};

    const _getFromLS = (localStorageKey) => {
      return $window.localStorage.getItem(localStorageKey);
    }


		component.config = _config;
		component.init = _init;
		component.addEventListeners = _addEventListeners;
		component.updateButtonContent = _updateButtonContent;
    component.getFromLS = _getFromLS;
    component.updateSelectedStore = _updateSelectedStore;
		return component;
	};

	return nearbyStore;
});
